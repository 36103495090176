import * as React from 'react';

import { Heading, Text, useBreakpointValue, Box } from '@chakra-ui/react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ContactForm from '../features/ContactForm';
import { maxW } from '../assets/config';
import JsonLd from '../components/JsonLd';

const ContactPage = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Layout>
      <Seo
        title="お問い合わせ"
        description="お問い合わせはこちらのページからお願いします。"
      />
      <JsonLd
        title="お問い合わせ"
        description="お問い合わせはこちらのページからお願いします。"
      />
      <Box maxW={maxW} mx="auto">
        <Heading
          fontSize={{ base: '3xl', md: '5xl', xl: '7xl' }}
          my={20}
          pb={4}
          borderBottom="2px solid black"
        >
          お問合せページ
        </Heading>
        <Text fontSize={{ base: '2xl', md: '3xl', xl: '4xl' }} mb={20}>
          ChokerJokerへのお問合せはこちらのフォームにご入力ください。
          <br />
          1〜3営業日以内に返答させて頂きます。
        </Text>
        <ContactForm />
      </Box>
    </Layout>
  );
};

export default ContactPage;
