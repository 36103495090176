import * as React from 'react';
import { chakra, Box, Button, Input, Textarea } from '@chakra-ui/react';

const ContactForm = () => (
  <chakra.form
    method="post"
    action="https://hyperform.jp/api/1tTinw2n"
    maxW="600px"
    mx="auto"
  >
    <Box my={4}>
      <chakra.label fontSize={{ base: '2xl', md: '4xl' }}>お名前</chakra.label>
      <Input
        size="lg"
        type="text"
        name="お名前"
        placeholder="例）山田太郎"
        required
      />
    </Box>
    <Box my={4}>
      <chakra.label fontSize={{ base: '2xl', md: '4xl' }}>
        メールアドレス
      </chakra.label>
      <Input
        size="lg"
        type="email"
        name="email"
        placeholder="例）example@example.com"
        required
      />
    </Box>
    <Box mt={4} mb={8}>
      <chakra.label fontSize={{ base: '2xl', md: '4xl' }}>
        お問い合わせ内容
      </chakra.label>
      <Textarea size="lg" name="お問い合わせ内容" required></Textarea>
    </Box>
    <Button type="submit" isFullWidth colorScheme="blue" size="lg" mt={8}>
      送信
    </Button>
  </chakra.form>
);

export default ContactForm;
